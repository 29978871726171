/* Dashboard Container */
.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

/* Header Styling */
.dashboard-header {
    background-color: #f8f9fa;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

/* Title Styling */
.dashboard-title {
    margin: 0;
    font-size: 1.5rem;
}

/* Navigation Styling */
.dashboard-nav {
    display: flex;
    align-items: center;
}

/* Button Styling */
.nav-button {
    margin-left: 10px;
    padding: 5px 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.nav-button:hover {
    background-color: #0056b3;
}

.nav-button:active {
    transform: scale(0.98);
}

/* Logout Button Specific Styling */
.logout-button {
    background-color: #dc3545;
}

.logout-button:hover {
    background-color: #c82333;
}

/* Map Container Styling */
.map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .dashboard-header {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }

    .dashboard-title {
        font-size: 1rem;
        margin-bottom: 10px;
    }

    .dashboard-nav {
        width: 100%;
        justify-content: space-around;
    }

    .nav-button {
        width: 45%;
        padding: 5px 0;
        font-size: .75rem;
    }
}

.dashboard-main {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
}