.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    padding: 20px 0;
    z-index: 1001;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    margin: auto;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1002;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.add-location-form input,
.add-location-form textarea,
.add-location-form select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.create-group-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-group-button:hover {
    background-color: #0056b3;
}

.submit-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #218838;
}

.create-group-form input,
.create-group-form textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.create-group-form .submit-button {
    background-color: #17a2b8;
}

.create-group-form .submit-button:hover {
    background-color: #138496;
}

@media (max-width: 600px) {
    .modal-content {
        padding: 15px;
        width: 95%;
        max-height: 80vh;
    }

    .close-button {
        top: 5px;
        right: 10px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .add-location-form input,
    .add-location-form textarea,
    .add-location-form select {
        font-size: 16px;
        /* Prevent zoom on mobile */
    }

    .submit-button,
    .create-group-button {
        width: 100%;
        margin-top: 10px;
    }
}