/* src/components/Groups/EditGroupModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1020;
}

.modal-content {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
}

.edit-group-form .form-group {
    margin-bottom: 15px;
}

.edit-group-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.edit-group-form .form-input,
.edit-group-form .form-textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.edit-group-form .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-group-form .submit-button:hover {
    background-color: #0056b3;
}

.edit-group-form .form-group input[type="checkbox"] {
    margin-right: 10px;
}

.public-group-label {
    display: flex;
    align-items: center;
    font-weight: normal;
}

.public-group-label input[type="checkbox"] {
    margin-right: 10px;
}

.create-group-form .form-group {
    margin-bottom: 15px;
}

.create-group-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.create-group-form .form-input,
.create-group-form .form-textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.create-group-form .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #008CBA;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.create-group-form .submit-button:hover {
    background-color: #007B9A;
}

.public-group-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.public-group-checkbox {
    width: 18px;
    height: 18px;
}

.public-group-label {
    font-weight: bold;
    margin-left: 10px;
    user-select: none;

}