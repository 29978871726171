.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1003;
}

.chat-feed-modal {
    background: #fff;
    padding: 15px 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.chat-feed-modal .close-button {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.chat-feed-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.chat-item {
    border-bottom: 1px solid #e1e8ed;
    padding: 10px;
}

.chat-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.chat-user-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.chat-user-info strong {
    font-weight: bold;
    color: #14171a;
    font-size: 1rem;
}

.chat-timestamp {
    margin-left: 10px;
    color: #657786;
    font-size: 0.85rem;
}

.chat-content {
    flex: 1;
}

.chat-content p {
    color: #14171a;
    font-size: 0.95rem;
    line-height: 1.4;
    margin: 5px 0;
}

.chat-image {
    max-width: 100%;
    border-radius: 10px;
    margin-top: 10px;
}

.reply-button {
    background: none;
    border: none;
    color: #1da1f2;
    cursor: pointer;
    font-size: 0.85rem;
    margin-top: 5px;
}

.reply-button:hover {
    text-decoration: underline;
}

.reply-form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reply-input {
    flex: 1;
    padding: 6px 10px;
    border: 1px solid #e1e8ed;
    border-radius: 30px;
    font-size: 0.95rem;
}

.send-reply-button {
    background-color: #1da1f2;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 0.85rem;
}

.send-reply-button:hover {
    background-color: #0d95e8;
}

.reply-item {
    display: flex;
    margin-top: 15px;
}

.replies-container {
    margin-left: 20px;
    margin-top: 10px;
}

.chat-feed-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.location-select {
    padding: 6px 10px;
    border: 1px solid #e1e8ed;
    border-radius: 30px;
    font-size: 0.95rem;
}

.chat-feed-input {
    flex: 1;
    padding: 6px 10px;
    border: 1px solid #e1e8ed;
    border-radius: 30px;
    font-size: 0.95rem;
}

.send-button {
    background-color: #1da1f2;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
}

.send-button:hover {
    background-color: #0d95e8;
}

.location-link {
    cursor: pointer;
    color: #1da1f2;
    text-decoration: underline;
    margin-right: 10px;
}

.poster-name {
    margin-left: 10px;
    font-weight: bold;
    color: #14171a;
    font-size: 1rem;
}

.chat-timestamp {
    margin-left: 10px;
    color: #657786;
    font-size: 0.85rem;
}

@media (max-width: 600px) {
    .chat-feed-modal {
        width: 95%;
        padding: 10px 15px;
    }

    .chat-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .chat-timestamp {
        margin-left: 0;
        margin-top: 5px;
    }

    .reply-form {
        flex-direction: column;
    }

    .chat-feed-form {
        flex-direction: column;
    }
}