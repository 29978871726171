.landing-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../../public/images/nautical-chart.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* Adjusted transparency */
    background-color: rgba(246, 241, 241, 0.9);
    /* Even more transparent */
    /* More transparent */
    background-blend-mode: overlay;
    color: #01010e;
    font-family: 'Arial', sans-serif;
    font-size: 1.3rem;
    text-align: center;
}

.content {
    max-width: 700px;
    padding: 20px;
}

.buttons {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
}

.register-button,
.login-button {
    display: inline-block;
    padding: 15px 30px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    transition: background-color 0.3s;
}

.register-button {
    background-color: #007bff;
}

.register-button:hover {
    background-color: #0056b3;
}

.login-button {
    background-color: #28a745;
}

.login-button:hover {
    background-color: #218838;
}