.custom-dropdown {
    position: relative;
    width: 150px;
}

.custom-dropdown-selected {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 0.9rem;
    cursor: pointer;
    position: relative;
    user-select: none;
}

.dropdown-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    transform: translateY(-50%);
    transition: transform 0.2s;
}

.dropdown-arrow.open {
    transform: translateY(-50%) rotate(180deg);
}

/* Updated dropdown list styles */
.custom-dropdown-list {
    position: absolute;
    bottom: 100%;
    /* Expand upwards */
    left: 0;
    right: 0;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1002;
    padding: 0;
    margin: 0;
    list-style: none;
    /* Remove bullet points */
}

.custom-dropdown-item {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.9rem;
    list-style: none;
    /* Ensure bullet points are removed */
    display: block;
}

.custom-dropdown-item:hover {
    background-color: #f0f0f0;
    /* Background color change on hover */
    color: #333;
    /* Optional: Text color change on hover */
}

/* Optional: Add focus state for keyboard navigation */
.custom-dropdown-item:focus {
    background-color: #e0e0e0;
    outline: none;
}