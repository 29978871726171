.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    height: 100%;
    /* Set height to 100% */
}

.messages-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow-y: auto;
    /* Allow scrolling for messages */
    flex-grow: 1;
}

/* Remove max-height and overflow properties */
.chat-container,
.messages-container {
    max-height: none;
    overflow: visible;
}

.chat-location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f2f5;
    border-bottom: 1px solid #e1e8ed;
    margin-bottom: 10px;
}

.location-name {
    font-weight: bold;
    color: #14171a;
}

.location-action {
    color: #1da1f2;
    cursor: pointer;
    transition: color 0.3s ease;
}

.location-action:hover {
    color: #0d8ecf;
}

.chat-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    border-bottom: 1px solid #e1e8ed;
    padding-bottom: 10px;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.chat-user-info {
    font-weight: bold;
    color: #14171a;
}

.chat-timestamp {
    font-size: 0.85rem;
    color: #657786;
}

.chat-content {
    flex: 1;
}

.chat-image {
    max-width: 100%;
    /* Reduce from 100% to 80% */
    max-height: 300px;
    /* Add a max-height */
    object-fit: contain;
    /* Ensure the entire image is visible */
    border-radius: 8px;
    cursor: pointer;
    margin: 5px 0;
}

.reply-button {
    background: none;
    border: none;
    color: #1da1f2;
    cursor: pointer;
    font-size: 0.85rem;
    padding: 0;
    margin-top: 5px;
}

.reply-form {
    display: flex;
    margin-top: 5px;
}

.reply-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #e1e8ed;
    border-radius: 15px;
    font-size: 0.9rem;
}

.send-reply-button {
    background-color: #1da1f2;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    margin-left: 5px;
}

.chat-form {
    padding: 10px;
    border-top: 1px solid #e1e8ed;
    background-color: #fff;
    /* Add this to ensure the form stays at the bottom */
    position: sticky;
    bottom: 0;
}

.chat-input-wrapper {
    display: flex;
    align-items: center;
    background-color: #f0f2f5;
    border-radius: 20px;
    padding: 5px 10px;
}

.chat-input {
    flex-grow: 1;
    border: none;
    background: transparent;
    font-size: 16px;
    padding: 8px;
    outline: none;
}

.chat-buttons {
    display: flex;
    align-items: center;
}

.image-upload-button,
.send-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 8px;
    color: #1877f2;
    transition: color 0.3s ease;
}

.image-upload-button:hover,
.send-button:hover {
    color: #166fe5;
}

.image-upload-button {
    margin-right: 8px;
}

/* Responsive styles */
@media (max-width: 480px) {
    .chat-container {
        height: auto;
        /* Remove fixed height */
    }

    .chat-location {
        flex-direction: column;
        align-items: flex-start;
    }

    .location-action {
        align-self: flex-end;
        margin-top: 5px;
    }

    .chat-form {
        padding: 10px;
    }

    .chat-input-wrapper {
        padding: 5px;
    }

    .chat-input {
        font-size: 14px;
    }

    .image-upload-button,
    .send-button {
        font-size: 18px;
        padding: 6px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .chat-container {
        max-height: 500px;
    }
}

@media (max-width: 600px) {
    .chat-container {
        height: 100%;
        /* Ensure full height on mobile */
    }

    .messages-container {
        flex-grow: 1;
        overflow-y: auto;
    }

    .chat-form {
        position: sticky;
        /* Keep it sticky on mobile */
        bottom: 0;
        background-color: #fff;
    }
}

/* Update the full-size image in the modal */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    /* Increase z-index to ensure it's on top */
}

.image-modal-content {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
}

.full-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.close-modal-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 2001;
    /* Ensure it's above the image */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .chat-image {
        max-width: 100%;
        /* Allow full width on small screens */
    }
}