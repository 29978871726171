.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1003;
    /* Ensure it's above other elements */
}

.help-modal-content {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.help-content h3 {
    margin-top: 20px;
    color: #333;
}

.help-content p {
    margin-bottom: 15px;
    color: #555;
    line-height: 1.6;
}