/* src/components/Map/LocationDetailModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    padding: 20px 0;
}

.modal-content {
    background-color: #fff;
    padding: 40px 20px 20px;
    /* Increase top padding */
    width: 90%;
    max-width: 900px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: none;
    overflow: visible;
}

.modal-content .close-button {
    position: absolute;
    top: 25px;
    /* Move down from 15px to 20px */
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1002;
}

.location-header-wrapper {
    overflow-y: auto;
    max-height: 50vh;
    padding-right: 10px;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.location-header-wrapper::-webkit-scrollbar {
    width: 0px;
    /* For Chrome, Safari, and Opera */
    background: transparent;
}

/* Show scrollbar on hover and when scrolling */
.location-header-wrapper:hover,
.location-header-wrapper:active {
    scrollbar-width: thin;
    /* For Firefox */
    -ms-overflow-style: auto;
    /* For Internet Explorer and Edge */
}

.location-header-wrapper:hover::-webkit-scrollbar,
.location-header-wrapper:active::-webkit-scrollbar {
    width: 8px;
    /* For Chrome, Safari, and Opera */
}

.location-header-wrapper:hover::-webkit-scrollbar-track,
.location-header-wrapper:active::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.location-header-wrapper:hover::-webkit-scrollbar-thumb,
.location-header-wrapper:active::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.location-header-wrapper:hover::-webkit-scrollbar-thumb:hover,
.location-header-wrapper:active::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.header-collapsed .location-header-wrapper {
    max-height: 0;
}

.location-header {
    padding-bottom: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
    /* Add top margin */
}

.location-title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.location-title {
    margin: 0;
    /* Remove any default margins */
}

.location-description {
    margin-top: 0;
    margin-bottom: 10px;
}

.images-slider {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    margin-bottom: 15px;
    padding: 5px 0;
    height: 120px;
    align-items: center;
}

.slider-image {
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}

.toggle-header-button {
    margin: 10px 0;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-section {
    flex-grow: 1;
}

.edit-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    color: #007bff;
    display: flex;
    align-items: center;
    margin-right: 10px;
    /* Add some space between the icon and the title */
}

.edit-icon-button:hover {
    color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .modal-overlay {
        padding: 0;
    }

    .modal-content {
        padding-top: 50px;
        /* Increase top padding for mobile */
        width: 100%;
        min-height: 100vh;
        border-radius: 0;
    }

    .close-button {
        top: 15px;
        /* Keep at 15px for mobile as per original CSS */
        right: 15px;
        font-size: 20px;
    }

    .location-header {
        margin-top: 10px;
        /* Adjust top margin for mobile */
    }
}

/* Add styles for webkit browsers to customize scrollbar */
.location-header-wrapper::-webkit-scrollbar {
    width: 8px;
}

.location-header-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.location-header-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.location-header-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Styles for the edit form */
.edit-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.form-group select {
    height: 40px;
}

.edit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.submit-button,
.cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button {
    background-color: #007bff;
    color: white;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
}

.submit-button:hover,
.cancel-button:hover {
    opacity: 0.8;
}

/* Image modal styles */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    /* Ensure it's above other modals */
}

.image-modal-content {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
}

.full-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.close-modal-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 2001;
    /* Ensure it's above the image */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .slider-image {
        height: 80px;
    }
}