/* src/components/Groups/EditGroupModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.modal-content {
    background: #fff;
    padding: 20px;
    /* Adjusted padding */
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    position: relative;
    overflow-y: auto;
    max-height: 90vh;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
}

/* Form Styles */
.edit-group-form .form-group {
    margin-bottom: 15px;
}

.edit-group-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.edit-group-form .form-input,
.edit-group-form .form-textarea {
    width: 100%;
    padding: 8px;
    /* Reduced padding */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.edit-group-form .form-textarea {
    resize: vertical;
    min-height: 80px;
}

/* Button Styles */
.action-button {
    width: 120px;
    /* Set fixed width */
    padding: 10px;
    background-color: #007bff;
    /* Default blue color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.action-button:hover {
    background-color: #0056b3;
}

.delete-button {
    width: 120px;
    /* Set fixed width */
    background-color: #dc3545;
    /* Red color for delete buttons */
}

.delete-button:hover {
    background-color: #c82333;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    /* Allow buttons to wrap on small screens */
    gap: 10px;
    margin-top: 15px;
}

/* Member List Styles */
.group-members {
    margin-top: 30px;
}

.group-members h3 {
    margin-bottom: 10px;
    color: #333;
}

.group-members ul {
    list-style: none;
    padding: 0;
}

.group-members li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    color: #555;
}

.group-members li:last-child {
    border-bottom: none;
}

/* Location List Styles */
.group-details {
    margin-top: 30px;
}

.group-details h3 {
    margin-bottom: 10px;
    color: #333;
}

.group-details ul {
    list-style: none;
    padding: 0;
}

.location-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    color: #555;
}

.location-item:last-child {
    border-bottom: none;
}

.location-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: nowrap;
    /* Prevent wrapping */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .modal-content {
        padding: 15px;
    }

    .button-group {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .location-actions {
        justify-content: space-between;
        width: 100%;
    }

    .action-button {
        width: 48%;
        /* Adjust to fit within parent */
        margin-right: 0;
    }
}