.map-view-container {
    position: relative;
}

/* Position map controls at the bottom center */
.map-controls {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    display: flex;
    gap: 10px;
    background: rgba(255, 255, 255, 0.9);
    padding: 5px 10px;
    border-radius: 5px;
    align-items: center;
}

.search-input,
.group-filter {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
}

.search-input {
    width: 150px;
}

.group-filter {
    width: 150px;
    /* Remove default appearance in mobile browsers */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10"><path fill="%23333" d="M7 10L0.503 0h13.994z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px 7px;
}

@media (max-width: 600px) {
    .map-controls {
        flex-direction: column;
        position: absolute;
        bottom: 180px;
        gap: 5px;
        padding: 10px;
    }

    .help-button-container {
        position: absolute;
        bottom: 200px;
        right: 20px;
        z-index: 1000;
    }

    .search-input,
    .group-filter {
        width: 70%;
    }

    .search-input {
        font-size: 1rem;
    }

    .group-filter {
        font-size: 1rem;

    }
}

.error-message {
    position: absolute;
    top: 130px;
    /* Adjusted to stay below map-controls */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
}

.loading-message {
    position: absolute;
    top: 130px;
    /* Adjusted to stay below map-controls */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 123, 255, 0.8);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1010;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

/* Customize the appearance of the zoom box (optional) */
.leaflet-zoom-box {
    border: 2px dashed #38a;
    background: rgba(0, 0, 255, 0.1);
}

/* Add these styles at the end of the file */

/* Style for the help button */
.help-button-container {
    position: absolute;
    bottom: 180px;
    right: 20px;
    z-index: 1000;
}

.help-button {
    background: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
    padding: 5px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.help-button:hover {
    background-color: #f4f4f4;
}

.fly-to-current-location-container {
    position: absolute;
    top: 80px;
    /* Adjust this value as needed */
    left: 10px;
    z-index: 1000;
}

.fly-to-current-location {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    cursor: pointer;
}

.fly-to-current-location:hover {
    background-color: #f4f4f4;
}