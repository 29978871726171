/* src/components/Groups/GroupModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-content ul {
    list-style: none;
    padding: 0;
}

.modal-content li {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.group-name {
    font-weight: bold;
}

.edit-button {
    padding: 5px 10px;
    max-width: 75px;
    background-color: #ffc107;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: #e0a800;
}

.create-group-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-group-button:hover {
    background-color: #45a049;
}